var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reset_password"},[(_vm.isResetPassword)?_c('div',[_c('div',{staticClass:"mw--72 mw-xl--176 mx-auto mb-25 mb-xl-23"},[_c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.$t('auth.completed_reset_password.message'))}})]),_c('div',{staticClass:"w--53 mw-100 mx-auto mb-25"},[_c('button',{staticClass:"btn btn-primary w-100 px-3 py-2 fs-15 rounded-5",on:{"click":_vm.goToLogin}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_back_login'))+" ")])])]):_c('div',{staticClass:"mw--72 mw-xl--176 mx-auto"},[_c('div',{staticClass:"mw-xl--144 mx-auto mb-9 text-center"},[_c('div',{staticClass:"mb-16 mb-xl-7"},[_vm._v(" "+_vm._s(_vm.$t('auth.reset_password.screen_title'))+" ")]),_c('div',{staticClass:"d-block",domProps:{"innerHTML":_vm._s(_vm.$t('auth.reset_password.message'))}})]),_c('div',{staticClass:"mb-10"},[_c('ValidationObserver',{ref:"observer",staticClass:"mw-xl--75 mx-auto",attrs:{"tag":"div"}},[_c('div',{staticClass:"align-items-baseline mb-6 mb-xl-11 position-relative"},[_c('label',{staticClass:"m-0 mb-1 w--50 flex-fixed pe-4 position-xl-absolute end-100 pt-xl-2",attrs:{"for":"new-password"}},[_vm._v(" "+_vm._s(_vm.$t('auth.reset_password.new_password')))]),_c('ValidationProvider',{staticClass:"flex-1",attrs:{"rules":{
              required: true,
              check_password: true,
              max: 255,
              no_script_tag: true
            },"name":"password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var touched = ref.touched;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_password),expression:"new_password"}],staticClass:"form-control px-4 py-2 fs-12 text-secondary-tint rounded-0",class:errors[0] && touched ? 'border-danger' : '',attrs:{"id":"new-password","name":"password","type":"password","placeholder":_vm.$t('auth.login.user_password')},domProps:{"value":(_vm.new_password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.new_password=$event.target.value},_vm.resetMsg]}}),(errors[0] && touched)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"d-xl-flex align-items-baseline mb-10 mb-xl-11 position-relative"},[_c('label',{staticClass:"w--50 flex-fixed pe-4 position-xl-absolute end-100 pt-xl-2",attrs:{"for":"confirm-new-password"}},[_vm._v(" "+_vm._s(_vm.$t('auth.reset_password.confirm_new_password')))]),_c('ValidationProvider',{staticClass:"flex-1",attrs:{"rules":{
              required: true,
              check_password: true,
              max: 255,
              no_script_tag: true
            },"name":"confirmPassword","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var touched = ref.touched;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_new_password),expression:"confirm_new_password"}],staticClass:"form-control px-4 py-2 fs-12 text-secondary-tint rounded-0",class:errors[0] && touched ? 'border-danger' : '',attrs:{"id":"confirm-new-password","name":"confirmPassword","type":"password","placeholder":_vm.$t('auth.reset_password.confirm_new_password_placeholer')},domProps:{"value":(_vm.confirm_new_password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.confirm_new_password=$event.target.value},_vm.resetMsg]}}),(errors[0] && touched)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(_vm.showErrorMsg)?_c('span',{staticClass:"text-danger d-block pt-2"},[_vm._v(_vm._s(_vm.$t('common.message.update_fail')))]):_vm._e(),(_vm.errorConfirmPassword)?_c('span',{staticClass:"text-danger d-block pt-2"},[_vm._v(" "+_vm._s(_vm.$t('common.message.error_confirm_password')))]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"w--53 mw-100 mx-auto"},[_c('button',{staticClass:"btn btn-primary w-100 px-3 py-2 fs-15 rounded-5 flex-center",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.resetPassword}},[(_vm.isLoading)?_c('div',{staticClass:"bars-loader"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_send_mail'))+" ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }